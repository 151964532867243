import { getUserProfile, updateProfile, customUpdateProfile, updateContact, updatePropertyPreference, postForm, getIdentityDocuments } from "../api";
import { has } from 'lodash';

// Redux store and profile action
import reduxStore from '../../../redux/store';
import { updateUserProfile } from '../../../redux/actions/profile';

import {
  storeUserData,
  doLogout,
} from '../utils';
import { API_ERROR_MESSAGE } from "../constants";
import { MYACCOUNT_LIGHT } from "@myaccountUrls";

const services = (dispatch, actions) => {

    const handleError = (error) => {
        if (error.response) {
          if (error.response?.data?.statusCode === 401) doLogout();
          dispatch(
            actions.authError({
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          dispatch(
            actions.serviceError({
              message: API_ERROR_MESSAGE,
              status: 500
            })
          );
        }
    }


    return ({
      getUserProfile: async (payload) => {
        dispatch(actions.profileLoading());
        await getUserProfile(payload)
          .then((res) => {
            storeUserData(res.data);

            dispatch(actions.getUserData(res));

            reduxStore.dispatch(updateUserProfile(res));
          })
          .catch((error) => {
            handleError(error)
          });
      },

      updateProfile: async (payload) => {
        // get the login user
        if (!payload.id) {
          dispatch(
            actions.tokenError({
              message: 'User id is required',
              status: '400'
            })
          );
        }

        let postData = {...payload}
        let trackData = postData?.trackData;

        if (postData?.successMessage)
            delete postData.successMessage

        let loading = true;
        if (postData.hasOwnProperty('enableLoading')) {
            loading = postData.loading;
            delete postData.enableLoading
        }
        if (postData?.trackData)
          delete postData.trackData

        if (!MYACCOUNT_LIGHT) {
            // update contact
            updateContact(postData);
        }

        if (has(postData, 'contact_id'))
            delete postData.contact_id

        if (loading)
            dispatch(actions.profileLoading());

        await updateProfile(postData)
          .then((res) => {

            if (trackData && typeof trackData === 'function') {
              trackData()
            }
            storeUserData(res.data);

            let response = {
                ...res,
            }
            if (payload?.successMessage)
                response['message'] = payload?.successMessage;

            dispatch(actions.profileUpdate(response));

            reduxStore.dispatch(updateUserProfile(response));
          })
          .catch((error) => {
            handleError(error)
          });
      },

      updatePropertyPreference: async (payload) => {
        // get the login user
        if (!payload.id) {
          dispatch(
            actions.tokenError({
              message: 'User id is required',
              status: '400'
            })
          );
        }

        let postData = {...payload}

        if (postData?.successMessage)
            delete postData.successMessage

        dispatch(actions.profileLoading());
        await updatePropertyPreference(payload)
          .then((res) => {
            storeUserData(res.data);
            let response = {
                ...res,
            }
            if (payload?.successMessage)
                response['message'] = payload?.successMessage;

            dispatch(actions.preferenceUpdate(response));

            reduxStore.dispatch(updateUserProfile(response));
          })
          .catch((error) => {
            handleError(error)
          });
      },

      completeProfile: async (payload) => {
          if (!payload.id) {
              dispatch(
                  actions.tokenError({
                      message: 'User id is required',
                      status: '400'
                  })
              );
          } else {
              dispatch(actions.completeProfileLoading());
             try {
                 const profileUpdateResponse = await customUpdateProfile({
                     ...payload.profile,
                     id: payload.id,
                 });

                 dispatch(actions.profileUpdate({
                     ...profileUpdateResponse,
                     loading: true,
                 }));

                 if (profileUpdateResponse?.data?.data) {
                     storeUserData(profileUpdateResponse.data.data);
                 }

                 if (['buyer', 'tenant'].includes(payload.profile.profile_persona) && payload.preferences/*&&
                     payload.createAlert === 'yes'*/) {
                      const propertyPreferenceUpdateResponse = await updatePropertyPreference({
                          ...payload.preferences,
                          id: payload.id,
                      });

                     dispatch(actions.preferenceUpdate({
                         ...propertyPreferenceUpdateResponse,
                         loading: true,
                     }));
                 }

                 if (['landlord', 'seller', 'both'].includes(payload.profile.profile_persona) &&
                     payload.bookValuation === 'yes'
                 ) {
                     await postForm(payload.valuation);
                 }

                 dispatch(actions.completeProfileSuccess({
                     message: payload.successMessage,
                 }));
             } catch (err) {
                 handleError(err)
             }
          }
      },

      updatePassword: async (payload) => {
        // get the login user
        if (!payload.id) {
          dispatch(
            actions.tokenError({
              message: 'User id is required',
              status: '400'
            })
          );
        }

        let trackData = payload?.trackData;

        if (payload?.trackData)
          delete payload.trackData

        await updateProfile(payload)
          .then((res) => {
            if (trackData && typeof trackData === 'function') {
              trackData()
            }
            dispatch(actions.passwordUpdate(res));
          })
          .catch((error) => {
            handleError(error)
          });
      },
      updateMessage: async (payload) => {
        dispatch(actions.updateMessage(payload));
      },
      resetMessage: async (payload) => {
        dispatch(actions.resetMessage(payload));
      },
      updateState: payload => {
        dispatch(actions.updateState(payload))
      },

      getIdentityDocuments: async (payload) => {
        dispatch(actions.profileLoading());
        await getIdentityDocuments(payload)
          .then((res) => {
            dispatch(actions.getIdentityDocuments(res));
          })
          .catch((error) => {
            handleError(error)
          });
      },

    })
}

export default services;
