import axios from "axios";
import { apiUrl, getToken, querystring } from './utils'

const baseURL = apiUrl()

// const CancelToken = axios.CancelToken;
// let cancel;

/**
 * Auth
 */
// Without header call
export const instance = axios.create({
  baseURL: baseURL,
});

// With header call
export const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

export const authInstanceDownload = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
  responseType: "blob"
});

// Updating authtoken in authInstance after login/register
const updateToken = (authToken) => {
  authInstance.defaults.headers["Authorization"] = `Bearer ${authToken}`;
};

const login = (values) => {
  // Call The login function
  return instance.post("/auth/local", {
    identifier: values.email,
    password: values.password,
  });
};

const register = (values) => {
  // Call The login function
  return instance.post("/auth/local/register", {...values, username:values.email});
};

const providerLogin = (values) => {
  // Call The login function
  return instance.get(`/stb-users/auth/${values.provider}/callback${values.params}`);
};

const forgotPassword = async (values) => {
  // Call The login function
  return await instance.post("/auth/forgot-password", values);
};

const emailConfirmation = async (params) => {
  // Call The login function
  // return await instance.get(`/auth/email-confirmation?${querystring(params)}`); // core
  return await instance.get(`/auth/confirm-email?${querystring(params)}`); // custom
};

const resetPassword = async (values) => {
  // Call The login function
  return await instance.post("/auth/reset-password", values);
};

const updateProfile = (params) => {
  return authInstance.put(`/users/${params.id}`, params);
};

const customUpdateProfile = (params) => {
  return authInstance.post(`/stb-users/updateprofile`, params);
};

const updateContact = (params) => {
  return authInstance.put(`/stb-users/updatecontact`, params);
};

const updateLastLogin = (params) => {
  return authInstance.put(`/stb-users/updatelastlogin`, params);
};

const getUserProfile = (params) => {
  return authInstance.get("/users/me", params);
};

const getIdentityDocuments = ( params ) => {
  return authInstance.get(`/myaccount/identityDocuments`);
};

const hasPersona = () => {
  return authInstance.get("/myaccount/has-personas");
};


/**
 * User objects
 */

const getUserobjects = (params) => {
  return authInstance.get(`/stb-users/userobjects?${querystring(params)}`);
};

const createUserobject = (params) => {
  return authInstance.post("/stb-users/userobjects", params);
};

const updateUserobject = (params) => {
  return authInstance.put(`/stb-users/userobjects/${params.id}`, params);
};

const deleteUserobject = (params) => {
  return authInstance.delete(`/stb-users/userobjects/${params.id}`, params);
};

const updatePropertyPreference = (params) => {
  return authInstance.post(`/stb-users/propertypreference`, params);
};

const getProperties = (params) => {
  return authInstance.get(`/properties?${querystring(params)}`);
};

const getCustomProperties = (params) => {
  return authInstance.post(`/properties/search`, params);
};

const unsubscribe = (params) => {
  return instance.put(`/stb-users/alert/property/unsubscribe`, params);
};

const getProperty = (params) => {
  return authInstance.get(`/myaccount/property/${params.id}`);
};

/**
 * Form
 */

const postForm = ( params ) => {
  return authInstance.post("/stb-forms/forms", params);
};

const getConfigValues = ( params ) => {
  const configAuthInstance = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
    },
  });

  return configAuthInstance.get(`/myaccount/getconfigvalues/${params.type}`);
};

const getTokenValues = ( params ) => {
  const configAuthInstance = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
    },
  });

  return configAuthInstance.post(`/stb-forms/actionviatoken`, params);
};


const getAvailabilitySlots = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/getviewingavailabilities`);
};

const getAvailabilitySlotsByPostcode = ( params ) => {
  return authInstance.get(`/myaccount/getvaluationavailabilities/${params.property_type}/${params.postcode}`); // :property_type/:postcode
};

const getAreas = ( params ) => {
  return authInstance.get(`/getareas`);
};

/**
 * Myaccount
 */

// get
const getDashboardData = ( params ) => {
  return authInstance.get(`/myaccount/dashboard?${querystring(params)}`);
};

const getMypropertiesData = ( params ) => {
  return authInstance.get(`/myaccount/myproperties?${querystring(params)}`);
};

const getArchivedProperties = ( params ) => {
  return authInstance.get(`/myaccount/getarchivedproperties`);
};

const getMytenanciesData = ( params ) => {
  return authInstance.get(`/myaccount/mytenancies?${querystring(params)}`);
};

const getMyoffersData = ( params ) => {
  return authInstance.get(`/myaccount/myoffers?${querystring(params)}`);
};

const getMyappointmentsData = ( params ) => {
  return authInstance.get(`/myaccount/myappointments?${querystring(params)}`);
};

const getActivityData = ( params ) => {
  return authInstance.get(`/myaccount/activity?${querystring(params)}`);
};

const getPendingActionData = ( params ) => {
  return authInstance.get(`/myaccount/pendingactions?${querystring(params)}`);
};

const getPendingActionCount = ( params ) => {
  return authInstance.get(`/myaccount/pendingactionscount?${querystring(params)}`);
};

const getNewsItems = ( params ) => {
    let url = 'blogs';
    if ( params?.endpoint ) {
        url = params?.endpoint
        delete(params['endpoint'])
    }
  return authInstance.get(`/${url}?${querystring(params)}`);
};

const countNewsItems = ( params ) => {
    let url = 'blogs';
    if ( params?.endpoint ) {
        url = params?.endpoint
        delete(params['endpoint'])
    }
  return authInstance.get(`/${url}/count`);
};

const getMyNegotiatorData = ( ) => {
  return authInstance.get(`/myaccount/negotiatordetails`);
};


// Post
const postAppointment = ( params ) => {
  return authInstance.post(`/myaccount/post-appointment`, params);
};

// Property

const getPropertyAppointments = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/appointments?${querystring(params)}`);
};

const getPropertyFeedbacks = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/feedbacks`);
};

const getPropertyOffers = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/offers`);
};

const getPropertyTenancies = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/tenancies`);
};

const getPropertyTransactions = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/transactions`);
};

const getPropertyTasks = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/tasks`);
};

const getWorksOrders = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/worksorders`);
};

const getTenancyDocuments = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/tenancydocuments`);
};

const getPropertyDocuments = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/propertydocuments`);
};

const downloadDocument = ( params ) => {
  authInstanceDownload.defaults.headers["Authorization"] = `Bearer ${getToken()}`;
  return authInstanceDownload.get(`/myaccount/document/${params.document_id}/download`);
};

const getPropertyPendingActions = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/pendingactions`);
};

const getPropertyRecentActivities  = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/activity`);
};

const getPropertyStats  = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/stats?${querystring(params)}`);
};

const getHasAppointments  = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/hasAppointments`);
};

const getCalendarList = ( params ) => {
  return authInstance.get(`/getcalendarlist?${querystring(params)}`);
};

const hideProperty = ( params ) => {
   return authInstance.get(`/myaccount/property/${params.property_id}/hideProperty`);
};

const unHideProperty = ( params ) => {
   return authInstance.get(`/myaccount/property/${params.property_id}/unHideProperty`);
};

const getActionAllowed  = ( params ) => {
  return authInstance.get(`/myaccount/property/${params.property_id}/actionAllowed`);
};

const getCall  = ( url) => {
    async function fetchData() {
        return await axios.get(`${url}`)
    }
    return fetchData();
};

export {
  // myaccount
  getDashboardData,
  getMypropertiesData,
  getArchivedProperties,
  getMytenanciesData,
  getMyoffersData,
  getMyappointmentsData,
  postAppointment,
  getActivityData,
  getPendingActionData,
  getPendingActionCount,
  getNewsItems,
  countNewsItems,
  getMyNegotiatorData,

  // Property
  getPropertyAppointments,
  getPropertyFeedbacks,
  getPropertyOffers,
  getPropertyTenancies,
  getPropertyTransactions,
  getPropertyTasks,
  getWorksOrders,
  getTenancyDocuments,
  getPropertyDocuments,
  getPropertyPendingActions,
  getPropertyRecentActivities,
  getPropertyStats,
  getHasAppointments,
  getCalendarList,
  hideProperty,
  unHideProperty,
  downloadDocument,

  // auth
  updateToken,
  login,
  register,
  getUserobjects,
  providerLogin,
  forgotPassword,
  resetPassword,
  getUserProfile,
  updateProfile,
  customUpdateProfile,
  updateContact,
  updateLastLogin,
  getProperties,
  getCustomProperties,
  getProperty,
  createUserobject,
  updateUserobject,
  deleteUserobject,
  updatePropertyPreference,
  emailConfirmation,
  postForm,
  getConfigValues,
  getAvailabilitySlots,
  getAvailabilitySlotsByPostcode,
  getAreas,
  unsubscribe,
  getCall,
  hasPersona,
  getIdentityDocuments,
  getActionAllowed,
  getTokenValues
};
