import Cookies from "universal-cookie"
import { get } from 'lodash';
import { MYACCOUNT_LOGIN } from "@myaccountUrls";
import { doRedirect } from "@myaccount/utils";
import { updateToken } from "./api"
const cookies = new Cookies()

const isBrowser = () => {
    return typeof window !== `undefined`
}

const apiUrl = () => {
    return process.env.REACT_APP_API_URL || process.env.GATSBY_API_URL || null
}

const cookieDomain =
    process.env.REACT_APP_COOKIE_DOMAIN ||
    process.env.GATSBY_COOKIE_DOMAIN ||
    null

const storeData = (key, value) => {
    if (isBrowser()) {
        // localStorage.setItem(key, JSON.stringify(value))
        const options = {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            sameSite: true,
            path: '/'
        }
        if (cookieDomain) options["domain"] = cookieDomain

        cookies.set(key, value, options)
    }
}

const getData = key => {
    return isBrowser() ? cookies.get(key) : null
    // return isBrowser() ? JSON.parse(localStorage.getItem(key)) : null
}

const removeData = key => {
    if (isBrowser()) {
        const options = {
            path: '/'
        }
        // Remove deafult
        cookies.remove(key, options)
        // Remove with domain deafult
        if (cookieDomain) options["domain"] = cookieDomain
        cookies.remove(key, options)
        // localStorage.removeItem(key)
    }
}

const storeUserData = user => {
    storeData("suser", {
        name: user.forename || user.username || user.email,
        surname: user.surname || null,
        email: user.email,
        mobile: user.mobile || user.telephone,
        // job_title: user?.job_title,
        uid: user.id,
        contact_id: get(user, 'contact_id[0]', false),
        picture: getUserPicture(user)
    })
}

const getUser = () => {
    return isBrowser() ? cookies.get("suser") : {}
    // return (isBrowser() ? JSON.parse(localStorage.getItem("suser")) : {});
}

const getToken = () => {
    return isBrowser() ? cookies.get("sjwt") : null
}

const getUserPicture = (user) => {
    return user?.picture || user?.social_picture || null;
}

const doLogout = () => {
    removeData('sjwt');
    removeData('suser');
    doRedirect(MYACCOUNT_LOGIN)
}

const querystring = (params) => {
    return new URLSearchParams(params).toString()
}

const storeToken = (data) => {
    // Store token
    if (isBrowser()) {
      storeData('sjwt', data.jwt);
      storeUserData(data.user);
    }
    updateToken(data.jwt);
  };

export {
    isBrowser,
    apiUrl,
    storeData,
    getData,
    getUserPicture,
    removeData,
    storeUserData,
    getUser,
    getToken,
    doLogout,
    querystring,
    storeToken
}
