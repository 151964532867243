import { has, set, get, isArray } from 'lodash';

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SERVICE_ERROR':
          return {
            ...state,
            loading: false,
            error: true,
            message: action.payload.message,
            status: action.payload.status
          };

        case 'API_ERROR':
            return {
              ...state,
              loading: false,
              error: true,
              message: action.payload.message,
              status: action.payload.status
            };


        case "FORM_REFRESH":
            return {
                ...state,
                loading: false,
                error: false,
                success: false,
                message: "",
            }
        case "FORM_LOADING":
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
                message: "",
            }

        case "DATA_LOADING":
            let params = {
                loading: true,
                error: false,
                success: false,
                message: "",
            }
            if (action?.payload)
                params = { ...params, ...action.payload }
            return {
                ...state,
                ...params
            }
        case "FORM_POST_ERROR":
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                message: "Error message!",
            }
        case "FORM_POST_SUCCESS":
            return {
                ...state,
                ...action.payload.data,
                loading: false,
                error: false,
                success: true,
                message: "Form Post Successfully.",
            }

        // GET
        case "GET_DASHBOARD_DATA":
            return {
                ...state,
                dashboardData: action.payload,
            }
        case "GET_MYPROPERTIES_DATA":
            return {
                ...state,
                loading: false,
                mypropertiesData: action.payload,
            }
        case "GET_MYTENANCIES_DATA":
            return {
                ...state,
                mytenanciesData: action.payload,
            }
        case "GET_MYOFFERS_DATA":
            return {
                ...state,
                myoffersData: action.payload,
            }
        case "GET_MYAPPOINTMENTS_DATA":
            return {
                ...state,
                myappointmentsData: action.payload,
            }

        // Post
        case "POST_APPOINTMENTS_SUCCESS":
            return {
                ...state,
                ...action.payload,
            }

        case "GET_PROPERTIES":
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                properties: action.payload,
            }

        case "GET_CUSTOM_PROPERTIES":

            const hits = get(action.payload, 'items', []);
            const total = get(action.payload, 'total', 0);
            const page = get(action.payload, 'page', 1);

            let newState = {
                ...state,
                loading: false,
                error: false,
                success: true,
                total: total,
                page: page
            }

            if (page === 1) {
                newState['properties'] = hits
                newState['page'] = 1
            } else {
                newState['properties'] = [
                    ...state.properties,
                    ...hits
                ]
            }

            console.log("newState => ", newState);


            return newState

        case "GET_PROPERTY":
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                property: action.payload,
            }
        case "GET_NEWS_ITEMS":
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                newsItems: [...(state.newsItems) || [], ...action.payload],
            };
        case "COUNT_NEWS_ITEMS":
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                newsItemsTotal: action.payload,
            };
        case "GET_NEWS_ITEM":
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                newsItem: action.payload,
            }
        case "GET_MY_NEGOTIATOR_DATA":
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                mynegotiator: action.payload,
            }

        case "CHANGE_STATE":
            if (has(state, action.payload.key)) {
                if (action.payload.postAction === 'remove') {
                    let targetItem = get(state, action.payload.key);
                    if (targetItem && isArray(targetItem)) {
                        let newItem = targetItem.filter(x => x.crm_id !== action.payload.crm_id);
                        let newState = {...state};
                        set(newState, action.payload.key, newItem);
                        return newState;
                    }

                } else if (action.payload.postAction === 'add') {
                   let targetItem = get(state, action.payload.key);
                   targetItem.unshift(action.payload)
                   let newState = {...state};
                   set(newState, action.payload.key, targetItem);
                   return newState;

               } else if (action.payload.postAction === 'update') {
                    let targetItem = get(state, action.payload.key);
                    let newState = {...state};
                    set(newState, action.payload.key, {...targetItem, ...action.payload});
                    return newState;
                }
            }
            return state;

        default:
            return state
    }
}

export default Reducer
